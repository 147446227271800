/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import './layout.css';
import 'typeface-roboto-mono';

type LayoutType = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutType> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div>
          <main>{children}</main>
        </div>
      </>
    )}
  />
);

export default Layout;
